import { navigate } from "@reach/router"
import axios from "./axios-cofig";

const isBrowser = typeof window !== `undefined`

const getUser = () =>
	window.localStorage.gatsbyUser
		? JSON.parse(window.localStorage.gatsbyUser)
		: {}
const get_groupdetails = () =>
	window.localStorage.group_details && window?.localStorage.group_details != "undefined"
		? JSON.parse(window.localStorage.group_details)
		: null
const getWeather = () =>
	window.localStorage.cachedWeather
		? JSON.parse(window.localStorage.cachedWeather)
		: null
const getCalendar = () =>
	window.localStorage.calendar_data
		? JSON.parse(window.localStorage.calendar_data)
		: null
const getAddress = () =>
	window.localStorage.currentlocation
		? JSON.parse(window.localStorage.currentlocation)
		: null

const getStatus = () =>
	window.localStorage.status
		? JSON.parse(window.localStorage.status)
		: ''
const getStatusCode = () =>
	window.localStorage.guide_status
		? JSON.parse(window.localStorage.guide_status)
		: ''

const getGuideAdminUser = () =>
	window.localStorage.guideAdmin
		? JSON.parse(window.localStorage.guideAdmin)
		: {}
export const setUser = user => (window.localStorage.gatsbyUser = JSON.stringify(user))
export const setGroup_Details = data => (window.localStorage.group_details = JSON.stringify(data))
export const setCalendarDetails = data => (window.localStorage.calendar_data = JSON.stringify(data))
export const setUserContext = userContext => (window.localStorage.userContext = JSON.stringify(userContext))
export const setCacheWeather = weather => (window.localStorage.cachedWeather = JSON.stringify(weather))
export const setMyAddress = address => (window.localStorage.currentlocation = JSON.stringify(address))
export const setStatus = status => (window.localStorage.status = JSON.stringify(status))
export const setGuideStatusValue = value => window.localStorage.guide_status = JSON.stringify(value)
export const getGuideStatusValue = () => isBrowser && getStatusCode()
export const setGuideAdminUser = guideAdminUser => (window.localStorage.guideAdmin = JSON.stringify(guideAdminUser))
export const getUserContext = () =>
	isBrowser && window.localStorage.userContext
		? JSON.parse(window.localStorage.userContext)
		: {}

export const handleLogin = () => {
	const nav = window.localStorage.getItem("pathname")
	if (!isBrowser) return false
	navigate(nav)
	return false
}



export const isLoggedIn = () => {
	if (!isBrowser) return false
	const user = getUser()
	return !!user.token
}

export const getCurrentGuideAdminUser = () => isBrowser && getGuideAdminUser()
export const getCurrentStatus = () => isBrowser && getStatus()
export const getGuideStatusCode = () => isBrowser && getStatusCode()
export const getCurrentUser = () => isBrowser && getUser()
export const getGroup_Details = () => isBrowser && get_groupdetails()
export const getCachedWeather = () => isBrowser && getWeather()
export const getCalendarDetails = () => isBrowser && getCalendar()
export const getMyAddress = () => isBrowser && getAddress()
export const isGuideAdmin = () => isBrowser && getGuideAdminUser() != null && getGuideAdminUser().token != undefined && getGuideAdminUser().token != null
export const logout = (callback = null) => {
	if (!isBrowser) return

	//window.localStorage.clear();
	setUser({})
	setMyAddress(null)
	if (callback instanceof Function) {
		callback()
	}
}
export const handlelogout = () => {
	if (!isBrowser) return
	//window.localStorage.clear();
	setUser({})
}

export const addAwsToken = async (awsToken, cognitoId, refreshtoken) => {
	var userStateDetail = {};
	userStateDetail.awsToken = awsToken;
	userStateDetail.cognitoId = cognitoId;
	userStateDetail.awsRefreshToken = refreshtoken
	userStateDetail.MessageConversations = [];
	userStateDetail.guideguid = getCurrentUser().guid
	//Refresh the AWs token for every 45 min.
	userStateDetail.initialLoad = true;
	userStateDetail.MessageCount = 0;
	userStateDetail.UnReadMessages = [];
}

export const getCorrectedImagePath = (image) => {
	if (image) {
		var correctPath = image.charAt(0) === '/' ? image.slice(1) : image
		//var returnPath = correctPath.replace(/\s/g, '%20')
		//console.log(correctPath)
		return correctPath;//correctPath.replace(/\s/g, '%20')
	}
}
export const formatPhoneNumber = (number) => {
	if (!number) return ""; // Handle null or undefined input

	let numStr = number?.toString()?.replace(/[^0-9+]/g, "");

	let countryCode = "";
	let formattedNumber = "";

	if (numStr?.startsWith("+1")) {
		countryCode = "+1";
		numStr = numStr?.slice(2); // Remove the country code
	} else if (numStr?.startsWith("1") && numStr?.length === 11) {
		countryCode = "+1";
		numStr = numStr?.slice(1); // Remove the leading 1
	} else if (numStr?.startsWith("+91")) {
		countryCode = "+91";
		numStr = numStr?.slice(3); // Remove the country code
	}

	// Format the remaining number
	if (numStr?.length >= 8) {
		formattedNumber = numStr?.replace(/(\d{3})(\d{3})(\d+)/, "($1) $2-$3");
	} else {
		// If the number isn't 10 digits, return the unformatted number
		formattedNumber = numStr;
	}

	return countryCode != "" ? `${countryCode} ${formattedNumber}` : formattedNumber;
};


const defaultOptions = {
	maxSizeMB: 1,          // (default: Number.POSITIVE_INFINITY)
	maxWidthOrHeight: 1024,   // compressedFile will scale down by ratio to a point that width or height is smaller than maxWidthOrHeight (default: undefined)
	useWebWorker: true,      // optional, use multi-thread web worker, fallback to run in main-thread (default: true)
};

// export const compressImage = async (file, options = {}) => await imageCompression(file,
// 	{ ...defaultOptions, ...options }
// )
