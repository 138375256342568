import React, { useState } from "react"
import GuideContext from "./GuideContext";

const GuideProvider = (props) => {
	const [loadingMask, setLoadingMask] = useState(false)
	const [showMenu, setShowMenu] = useState(false)
	const [guideStatus, setGuideStatus] = useState({})
	const [guideDetails, setGuideDetails] = useState({})
	const [guidePhoto, setGuidePhoto] = useState([])
	const [profileImage, setProfileImage] = useState()
	const [validateSubmit, setvalidateSubmit] = useState(false)
	const [company, setCompany] = useState([])
	const [groupMembers, setGroupMembers] = useState([])
	const [grpPermissions, setGrpPermissions] = useState([])
	const [guideConnectedAccount, setGuideConnectedAccount] = useState({});
	const [categorySettings, setCategorySettings] = useState()
	const [currentGuideValue, setCurrentGuideValue] = useState()
	const [crmOverlay, setCrmOverlay] = useState(false)


	return (
		<GuideContext.Provider value={{
			loadingMask, setLoadingMask,
			guideStatus, setGuideStatus,
			guideDetails, setGuideDetails,
			guidePhoto, setGuidePhoto,
			profileImage, setProfileImage,
			showMenu, setShowMenu,
			validateSubmit, setvalidateSubmit,
			company, setCompany,
			groupMembers, setGroupMembers,
			grpPermissions, setGrpPermissions,
			guideConnectedAccount, setGuideConnectedAccount,
			categorySettings, setCategorySettings,
			currentGuideValue, setCurrentGuideValue,
			crmOverlay, setCrmOverlay
		}}
		>
			{props.children}
		</GuideContext.Provider>
	)
}

export default GuideProvider
